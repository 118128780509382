import React from "react";
import "./Main.css";
import Chart from "./Chart";

const Main = () => {
  const schedules = [
    { month: "January", events: ["Basic Gaming", "Daily Streak", "Advanced Task"] },
    { month: "February", events: ["Checkin Rewards", "Gaming Integration", "Ton Block chain Announcement"] },
    { month: "March", events: ["TGE Announcement"] },
    // { month: "April", events: ["Even 1", "Event 2", "Event 3", "Event 4"] },
    // { month: "May", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
    // { month: "June", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
    // { month: "July", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
    // { month: "August", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
    // {
    //   month: "September",
    //   events: [
    //     "The Interlude season launch",
    //     "The Interlude season launch",
    //     "The Interlude season launch",
    //     "The Interlude season launch",
    //   ],
    // },
    // { month: "October", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
    // { month: "November", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
    // { month: "December", events: ["Event 1", "Event 2", "Event 3", "Event 4"] },
  ];
  return (
    <div className="main">
      <Chart />
      <h2>Roadmap</h2>
      <div className="schedules">
        {schedules.map((schedule, index) => (
          <div className="schedule" key={index}>
            <div className="sche1">
              <h1>{schedule.month}</h1>
            </div>
            <div className="sche2">
              <ul>
                {schedule.events.map((event, eventIndex) => (
                  <li key={eventIndex}>{event}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <h2>TO BE REVEALED SOON</h2>
    </div>
  );
};

export default Main;
