import React from 'react'
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'
import Overview from '../components/Overview'
const Home = () => {
  return (
    <>
        <Header/>
        <Overview/>
        <Main/>
        <Footer/>
    </>
  )
}

export default Home