import React from "react";
import "./Header.css";
import Logo from "../assets/pic.jpg";
import White from "../assets/whitepaper.pdf";
const Header = () => (
  <div className="header">
    <div className="box1">
      <a
        href="https://x.com/white_sharkio?t=rXX00BdiSom5FX19dys-bg&s=08"
        target="_blank"
      >
        <i class="fa-brands fa-x-twitter"></i>
      </a>
      <a href="https://t.me/TheWhiteShark_io" target="_blank">
        <i class="fa-brands fa-telegram"></i>
      </a>

      <span>Join us</span>
    </div>
    <div className="box2">
      <div className="box21">
        <div className="coin-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="box212">
          <h1>TheWhiteShark</h1>
        </div>
      </div>
      <div className="box22">
        {/* <div className="join">
            <div className="address">
              <p>contract address</p>
              <p>EQAJ8uWd7EBqsmpSWaRdf_I-8R8-XHwh3gsNKhy-UrdrPcUo</p>
            </div>
            <div className="copy">
              <i class="fa-regular fa-copy"></i>
            </div>
          </div> */}
      </div>
    </div>
    <div className="box1">
      <a href={White} target="_blank">
        Whitepaper
      </a>
    </div>
  </div>
);

export default Header;
