import React from 'react';

const Overview = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Overview</h1>
      <p style={styles.text}>
        The White Shark is a crypto airdrop game built on Telegram, designed to engage and reward users
        through innovative gameplay. Its mission is to onboard millions of Web2 users into the exciting world of
        Web3.
      </p>
      <p style={styles.text}>
        The White Shark is poised to make waves with its unique features and community-driven approach. Here's
        what sets us apart:
      </p>
      <ul style={styles.list}>
        <li>A growing community of crypto enthusiasts ready to dive into the action.</li>
        <li>Seamless integration of device motion tracking, a standout feature in the gaming ecosystem.</li>
        <li>Daily tasks and challenges designed to keep users engaged and rewarded.</li>
        <li>An engaging airdrop ecosystem that offers real-world value to players.</li>
      </ul>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    margin: '2rem auto',
    maxWidth: '800px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '34px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  text: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '15px',
  },
  list: {
    textAlign: 'left',
    listStyleType: 'disc',
    color: 'skyblue',
    paddingLeft: '20px',
    margin: '0 auto',
    maxWidth: '500px',
  },
};

export default Overview;
