import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./Chart.css"; // Importing the new CSS file

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = () => {
  const data = {
    labels: [
      "Community Allocation",
      "Core Team",
      "Ecosystem Development Fund",
      "Liquidity and Exchange Listings",
      "Advisors and Strategic Partnerships",
      "Fundraising",
    ],
    datasets: [
      {
        data: [70, 8, 5, 5, 5, 7], // Percentages
        backgroundColor: [
          "#28a745", // Green for Community Allocation
          "#007bff", // Blue for Core Team
          "#ffc107", // Yellow for Ecosystem Development Fund
          "#fd7e14", // Orange for Liquidity
          "#6f42c1", // Purple for Advisors
          "#dc3545", // Red for Fundraising
        ],
        hoverOffset: 10,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw || 0;
            const total = 10_000; // Total tokens in millions
            const allocation = (total * value) / 100; // Calculate in TWS
            return `${label}: ${value}% (${allocation.toLocaleString()} million TWS)`;
          },
        },
      },
      legend: {
        position: "right",
        labels: {
          boxWidth: 20,
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 10,
        },
        onResize: (chart, size) => {
          if (size.width < 768) {
            chart.options.plugins.legend.position = "bottom";
          } else {
            chart.options.plugins.legend.position = "right";
          }
        },
      },
    },
  };

  return (
    <div className="chart-container">
      {/* Title */}
      <div className="boxtoken">
        <h1 className="chart-title">The White Shark Tokenomics</h1>

        {/* Total Supply */}
        <h3 className="chart-supply">
          <strong>Total Supply:</strong> 10,000,000,000 (10 billion TWS)
        </h3>
        <h4 className="chart-description">
          Tokenomics Breakdown of The White Shark (TWS)
        </h4>
      </div>
      <Pie data={data} options={options} />
    </div>
  );
};

export default Chart;
